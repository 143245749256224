export const enAscentTranslations = {
  "aboutSBA": "About SBA",
  "aboutSbaDotGov": "About SBA.gov",
  "accessibility": "Accessibility",
  "advocacy": "Advocacy",
  "blog": "Blog",
  "browse": "Browse",
  "browseJouneys": "Browse Journeys",
  "certifications": {
    "label": "What type of certifications do you hold?",
    "options": {
      "smallBusiness": "Small Business (self certification)", 
      "wosb": "WOSB or EDWOSB (SBA certification)", 
      "hz": "HZ (SBA certification)", 
      "8a": "8a (SBA certification)", 
      "vet": "VET (SBA certification)", 
      "sdvosb": "SDVOSB (SBA certification)"
    }
  },
  "certifiedGovernementContracting": {
    "label": "Are you certified for government contracting?",
    "options": {
      "yes": "Yes",
      "no": "No",
      "notSure": "I don't know what that means"
    }
  },
  "confirmationForm": {
    "title": "Confirm your account with the confirmation code sent to the address.",
    "fields": {
      "confirmationCode": "Confirmation Code",
      "emailAddress": "Email Address"
    },
    "buttons": {
      "confirmAccount":"Confirm Account"
    },
    "messages": {
      "verified": "Congratulations, your account has been confirmed!"
    }
  },
  "congratulations": "Congrats",
  "considerMyself": {
    "label": "I consider myself",
    "options": {
      "americanIndianOrAlaskaNative": "American Indian or Alaska Native", 
      "asian": "Asian", 
      "blackOrAfricanAmerican": "Black or African American", 
      "nativeHawaiianOrOtherPacificIslander": "Native Hawaiian or other Pacific islander", 
      "white": "White"
    }
  },
  "contactSBA": "Contact SBA",
  "course": "Course",
  "courses": "Courses",
  "createABusinessPlan": "Create a Business Plan",
  "customerService": "Customer Service",
  "dataStore": "Data Store",
  "dashboard": "Dashboard",
  "dashboardCTA": {
    "images": {
      "trackProgress": {
        "alt": "Track",
        "description": "Track your progress through your MySBA Learning Journey."
      },
      "resume": {
        "alt": "Resume",
        "description": "Resume where you left off"
      },
      "receiveUpdates": {
        "alt": "Receive Updates",
        "description": "Receive future updates about MySBA Learning"
      }
    },
    "cta": "The SBA is excited to provide this educational offering. Establishing an account on MySBA Learning will provide the following unique benefits:"
  },
  "deleteAccount": {
    "paragraphOne": "We're sad to see you go.",
    "paragraphTwo": "Deleting your MySBA Learning account will immediately take effect. All Journey progress tracking and saved profile information will be permanently removed.",
    "paragraphThree": "Are you sure you want to delete your MySBA Learning account?",
    "confirmButton": "Delete Account",
    "cancelButton": "Cancel"
  },
  "deleteSuccess": {
    "paragraphOne": "Your account has been successfully deleted.",
    "paragraphTwo": "We wish you all the best in your business endeavors.",
    "paragraphThree": "Please be sure to come back at any time!",
    "close": "Close"
  },
  "demographicSuccess": {
    "buttons": {
      "success": "Get started now!"
    }
  },
  "disclaimers": "Disclaimers",
  "downloadButton": {
    "buttons": {
      "download": "Download"
    }
  },
  "dropdownQuestion": {
    "controls": {
      "dropdown": "Choose one"
    }
  },
  "editProfileForm": {
    "passwordMessage": "If you would like to change your password, type a new one below.",
    "fields": {
      "currentPassword": "Current Password",
      "confirmationPassword": "Confirm New Password"
    },
    "cancelButton": "Cancel",
    "saveButton": "Save",
    "emailChangedMessage": {
      "paragraphOne": "Please take a look at your inbox for an email verifying your new email address. You will not be able to receive any emails from MySBA Learning until your new email address is verified.",
      "paragraphTwo": "Note: You must verify your account to use the 'Forgot Password' feature."
    },
    "successMessage": "Your profile has been successfully updated."
  },
  "eliminatingFraudWasteAndAbuse": "Eliminating Fraud, Waste and Abuse",
  "emailAddress": "Email Address",
  "emailConfirmationForm": {
    "header": {
      "confirmed": "Email Verified!",
      "unconfirmed": "Confirm New Email"
    },
    "instructions": "Confirm your new email address with the confirmation code sent to that address.",
    "fields": {
      "confirmationCode": "Confirmation Code",
      "submit": "Confirm Account"
    },
    "verificationMessages": {
      "success": "Your email has been verified!",
      "email": "You will now be able to receive emails from MySBA Learning to {{email}}."
    }
  },
  "employeeGoal": {
    "label": "In twelve months, my goal is to increase my number of employees by",
    "options": {
      "percent": "percent (%)",
      "number": "number of employees (#)"
    }
  },
  "enable2FaScreen": {
    "instructions": "Please provide a mobile phone number capable of receiving text messages. This number will be used to verify your identity when logging in by sending you a secret code.",
    "fields": {
      "phoneNumber": "Phone Number"
    },
    "buttons": {
      "confirm": "Confirm Phone Number"
    }
  },
  "eventFooterButtons": {
    "buttons": {
      "previous": "Previous"
    }
  },
  "eventTypes": {
    "journey": "Journey",
    "Journey": "Journey",
    "firesideChat": "Fireside Chat",
    "Fireside Chat": "Fireside Chat",
    "infographic": "Infographic",
    "Infographic": "Infographic",
    "keyInsights": "Key Insights",
    "Key Insights": "Key Insights",
    "keyTakeaway": "Key Takeaway",
    "Key Takeaway": "Key Takeaway",
    "successStory": "Success Story",
    "Success Story": "Success Story",
    "tool": "Tool",
    "Tool": "Tool",
    "video": "Video",
    "Video": "Video",
    "objective": "Objective"
  },
  "externalSiteModal": {
    "buttons": {
      "close": "Close",
      "continue": "Continue"
    },
    "text": {
      "linkToWebsite": "Link to Website"
    }
  },
  "excursion": "Excursion",
  "facebookAria": "Go to external Facebook site will open new tab",
  "federalGovernmentProcurement": {
    "label": "Are you currently procuring through the federal government?",
    "options": {
      "yes": "Yes",
      "no": "No",
      "notSure": "I don't know what that means"
    }
  },
  "findEvents": "Find Events",
  "firstName": "First Name",
  "finish": "Finish",
  "forgotPasswordForm": {
    "instructions": "Enter the email address associated with this account. Follow instructions in the email in order to reset your password.",
    "button": "Reset Password",
    "messages": {
      "success": "A password reset link will be emailed to: "
    }
  },
  "freedomOfInformationAct": "Freedom of Information Act",
  "fraudAndAbuse": "Report Fraud, Waste and Abuse",
  "genderIdentity": {
    "label": "Sex",
    "options": {
      "male": "Male",
      "female": "Female"
    }
  },
  "haveAccount": "Already have an account?",
  "home": "Home",
  "inBusiness": {
    "label": "Are you in business?",
    "options": {
      "yes": "Yes",
      "no": "No"
    }
  },
  "initiatives": "Initiatives",
  "inspectorGeneral": "Inspector General",
  "journey": "Journey",
  "journeys": "Journeys",
  "journeyProgress": {
    "message": "{{complete}} out of {{total}} paths completed!"
  },
  "journeyResume": {
    "resume": "Resume"
  },
  "lastName": "Last Name",
  "learningEventPage": {
    "fields": {
      "nameForCertificate": "Name for Certificate"
    },
    "buttons": {
      "printCertificate": "Print Certificate"
    },
    "messages": {
      "notCompleted": "You must complete all {{pathTerm}} before printing your certificate!"
    }
  },
  "learningEventPodcast": {

  },
  "linkingPolicy": "Linking Policy",
  "login": "Login",
  "loginModal": {
    "titles": {
      "welcome": "Welcome",
      "verifyCode": "Verify Code",
      "enable2Fa": "Enable Two-Factor Authentication"
    },
    "buttons": {
      "close": "Close"
    }
  },
  "loginScreen": {
    "fields": {
      "email": "Email",
      "password": "Password",
      "rememberMe": "Remember Me"
    },
    "buttons": {
      "submit": "Sign In",
      "forgotPassword": "Forgot Password?",
      "register": "Register"
    },
    "messages": {
      "forgotPassword": "Forgot your password?",
      "or": "or",
      "haveAccount": "Don't have an account?"
    }
  },
  "hearingsAndAppeals": "Hearings and Appeals",
  "helperFunctions": {
    "minute": "minute",
    "minutes": "minutes",
    "seconds": "seconds"
  },
  "mediaAndPressRelations": "Media and Press Relations",
  "middleName": "Middle Name",
  "militaryStatus": {
    "label": "Military Service Status",
    "options": {
      "activeServiceMember": "Active Service Member",
      "veteran": "Veteran",
      "militarySpouse": "Military Spouse",
      "nationalGuardOrReserve": "National Guard/Reserve",
      "serviceDisabledVeteran": "Service-Disabled Veteran",
      "noMilitaryStatus": "No Military Status"
    }
  },
  "myEthnicity": {
    "label": "I consider my ethnicity",
    "options": {
      "hispanicOrLatino": "Hispanic or Latino",
      "notHispanicOrLatino": "Not Hispanic or Latino",
      "preferNotToDisclose": "Prefer not to disclose"
    }
  },
  "mediaTranscriptContent": {
    "headers": {
      "about": "About the Speaker(s)",
      "references": "References",
      "resources": "Resources",
      "transcript": "Transcript"
    },
    "buttons": {
      "download": "Link for video transcript sources"
    }
  },
  "navigationUserDropdown": {
    "hi": "Hi",
    "dashboard": "Dashboard",
    "dashboarddAria": "Go to Dashboard",
    "dropdownAria": "Expand User Dashboard Profile Log Out menu",
    "profile": "Profile",
    "profileAria": "Go to Profile",
    "logout": "Log Out",
    "logoutAria": "Log Out"
  },
  "nameOfBusiness": "Name of Business",
  "noFearAct": "No Fear Act",
  "next": "Next",
  "ombudsman": "Ombudsman",
  "onlineTraining": "Online Training",
  "openGovernment": "Open Government",
  "oversight": "Oversight",
  "password": "Password",
  "passwordConfirm": "Confirm Password",
  "passwordRules": {
    "oneUpperCase": "At least one upper case letter",
    "oneLowerCase": "At least one lower case letter",
    "oneNumber": "At least one number",
    "oneSpecialCharacter": "At least one special character",
    "twelveCharacters": "At least 12 characters",
    "matchingPasswords": "Passwords must match"
  },
  "path": "path",
  "pathCompleted": {
    "paragraphs": {
      "one": "Congrats!",
      "two": "You've finished your Journey!"
    }
  },
  "pathProgressBar": {
    "title": "Your Progress",
    "outOf": "out of",
    "completed": "completed"
  },
  "pathRegister": {
    "message": "Track your progress!"
  },
  "percentOfBusiness": "Percent of business you own",
  "performanceBudgetAndFinancing": "Performance, Budget and Financing",
  "plainLanguage": "Plain Language",
  "policyAndRegulation": "Policy and Regulation",
  "postalCode": "Postal Code",
  "previous": "Previous",
  "privacyPolicy": "Privacy Policy",
  "profile": {
    "title": "My Profile",
    "changeTwoFactor": "Change Two-Factor Phone Number",
    "profileLoadError": "Error loading your profile information, please log out or refresh the page and try again. If the problem persists please contact our Support Center.",
    "resendVerification": "Resend verification email",
    "resendVerificationError": "There was an error resending your confirmation e-mail. Please log out or refresh the page and try again. If the problem persists please contact our Support Center.",
    "twoFactor": "Two-Factor Settings",
    "editProfile": "Edit Profile",
    "deleteAccount": "Delete Account"
  },
  "profileModal": {
    "types": {
      "editProfile": "Edit Profile",
      "deleteAccount": "Delete Account",
      "deleteSuccess": "Account Deleted",
      "verficationSent": "Verfication Email Sent!"
    }
  },
  "qualityForGovernmentContracts": "Quality for Government Contracts",
  "registration": {
    "paragraphOne": "The Small Business Act, Public Law (PL) 85-536 authorizes the Small Business Administration (SBA) to collect the information on this electronic format. It is mandatory to register with this learning platform and we will be collecting Name, Zip Code and Email information. By registering you consent to the collection process and storage of your information. In addition, you agree to all SBA.gov policies and disclaimers, including those specifically related to the MySBA Learning platform, located",
    "here": "here",
    "paragraphTwo": "PURPOSE: The Small Business Administration (SBA) developed a new e-learning and networking platform for women entrepreneurs interested in accessing resources to support growing an existing business. Anyone can access to the content on the system after registration. It is mandatory that users create an account by completing all the items marked with an asterisk (*) on the Registration Form. The information collection will enable SBA to better determine who is using the platform and the scope of their participation in the a, and to develop a platform that would enable the user to tailor delivery of content to meet their needs. The information will also facilitate user connectivity to relevant resources (e.g., peer-to-peer learning, networking, mentoring)",
    "paragraphThree": "The information provided will be protected to the extent permitted by law, including the Freedom of Information Act and the Privacy Act. Personally identifiable information that is used to retrieve information or any individual responding to this or other request for information related to SBA training resources is maintained in SBA's Privacy Act System of Records, SBA - 5 Business and Community Initiatives.",
    "controlNumber": "OMB Control Number: 3245-0399",
    "exiprationDate": "Expiration Date: 05/31/2022",
    "paragraphFour": "NOTE: The Paperwork Reduction Act mandates that all federal government agencies receive approval from OMB. According to the Paperwork Reduction Act, you are not required to respond to the questions asked on this registration form unless it displays a valid OMB Control Number. The estimated time to complete this registration is 8 minutes, including time for reading the instructions. Send comments regarding this estimated time, or any other aspect of this survey to the Chief, Records Management Division, Small Business Administration, 409 Third Street, SW. Washington, DC 20416; or SBA Desk Officer, Office of Management and Budget, New Executive Office Building, Rm. 10202, Washington, DC 20006."
  },
  "registrationComplete": {
    "congratulations": "Congrats,",
    "paragraphOne": "You've successfully registered!",
    "paragraphTwo": "Please take a look at your inbox to verify your email and begin your Journey!"
  },
  "registrationModal": {
    "titles": {
      "signUp": "Register",
      "confirmAccount": "Confirm Account",
      "success": "Register",
      "forgotPassword": "Reset Password",
      "changePassword": "Change Password"
    }
  },
  "register": "Register",
  "registerButton": {
    "buttonText": "Register"
  },
  "regulationsDotGov": "Regulations.gov",
  "resetPasswordForm": {
    "instructions": "Confirm your email with the confirmation code sent to the address.",
    "fields": {
      "confirmationCode": "Confirmation Code",
      "newPassword": "New Password",
      "confirmationPassword": "Confirm New Password"
    },
    "buttons": {
      "submit": "Change Password",
      "close": "Close"
    },
    "messages": {
      "success": "Your password has changed. Please click the button below to close this window."
    }
  },
  "response2FaScreen": {
    "helpDeskLink": "Contact the Support Center",
    "didNotReceiveCode": "Did not receive code?",
    "checkPhoneNumber": "Check your phone number.",
    "needMoreAssistance": "Need more assistance?",
    "fields": {
      "mfaCode": "MFA Code"
    },
    "messages": {
      "success": "We texted your mobile phone number. Please enter the code below to sign in."
    },
    "buttons": {
      "submit": "Verify MFA Code"
    }
  },
  "revenueGoal": {
    "label": "In twelve months, my goal is to increase my revenue by",
    "options": {
      "percent": "percent (%)",
      "dollars": "dollars ($)"
    }
  },
  "save": "Save",
  "sbaAria": "Go to external U.S. Small Business Administration site will open new tab",
  "sbaInformation": "SBA Information",
  "sbaInstagramAria": "Go to external SBA Instagram site will open new tab",
  "sbaLinkedInAria": "Go to external SBA LinkedIn site will open new tab",
  "sbaLocations": "SBA Locations",
  "sbaPerformance": "SBA Performance",
  "sbaTeam": "SBA Team",
  "sbaVideos": "SBA Videos",
  "sbaYoutubeAria": "Go to external SBA Youtube site will open new tab",
  "search": "Search",
  "shareModal": {
    "share": "Share",
    "shareThis": "Share this",
    "copy": "Copy",
    "copied": "Copied!"
  },
  "signUpForm": {
    "title": "Business Contact Information"
  },
  "siteMap": "Site Map",
  "socialMedia": "Social Media",
  "strategicPlanning": "Strategic Planning",
  "submit": "Submit",
  "support": "Support",
  "supportAria": "Go to external Help desk support site will open new tab",
  "topicContentSelect": {
    "controls": {
      "select": "Select an Excursion..."
    }
  },
  "toolsAndFeatures": "Tools and Features",
  "totalEmployees": {
    "label": "Total number of employees",
    "selfEmployed": "Self-Employed",
    "oneToTen": "1-10 employees",
    "elevenToFifty": "11-50 employees",
    "fiftyOneToTwoHundred": "51-200 employees",
    "twoHundredOneToFiveHundred": "201-500 employees",
    "fiveHundredPlus": "500+ employees"
  },
  "tour": "Take A Tour",
  "tourAria": "Open modal to take a tour of site features",
  "typeOfBusiness": {
    "label": "Type of business (industry)",
    "retail": "Retail",
    "construction": "Construction",
    "informationTechnology": "Information Technology",
    "utilities": "Utilities",
    "mining": "Mining",
    "manufacturing": "Manufacturing",
    "financeAndInsurance": "Finance and Insurance",
    "wholesaleTrade": "Wholesale Trade",
    "publicAdministration": "Public Administration",
    "educationalServices": "Educational Services",
    "realEstateAndRentalAndLeasing": "Real Estate and Rental and Leasing",
    "healthcareAndSocialAssistance": "Health Care and Social Assistance",
    "accommodationsAndFoodServices": "Accommodations and Food Services",
    "artsEntertainmentAndRecreation": "Arts, Entertainment, and Recreation",
    "transportationAndWarehousing": "Transportation and Warehousing",
    "professionalScientificAndTechnicalServices": "Professional, Scientific, and Technical Services",
    "managementOfCompaniesAndEnterprises":  "Management of Companies and Enterprises",
    "agricultureForestryFishingAndHunting": "Agriculture, Forestry, Fishing, and Hunting",
    "administrativeAndSupport": "Administrative and Support",
    "wasteManagementAndRemediationServices": "Waste Management and Remediation Services",
    "other": "Other"
  },
  "usaDotGov": "USA.gov",
  "verificationSent": {
    "paragraphs": {
      "one": "A verification email has been sent to: ",
      "two": "Please take a look at your inbox for an email verifying your new email address. You will not be able to receive any emails from MySBA Learning until your new email address is verified.",
      "three": "Note: You must verify your account to use the 'Forgot Password' feature."
    },
    "buttons": {
      "close": "Close"
    }
  },
  "whitehouseDotGov": "Whitehouse.gov",
  "xAria": "Go to external X site will open new tab"
}

 