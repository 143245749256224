import React from "react";
import ReactDOM from "react-dom";
import { withTranslation } from 'react-i18next';
import PropTypes from "prop-types";
import { Modal, Grid, Button, Icon } from "semantic-ui-react";
import FocusLock from "react-focus-lock";
import SignUpForm from "../SignUpForm/SignUpForm";
import ConfirmationForm from "../ConfirmationForm/ConfirmationForm";
import RegistrationComplete from "./RegistrationComplete";
import ForgotPasswordForm from "../ForgotPasswordForm/ForgotPasswordForm";
import ResetPasswordForm from "../ResetPasswordForm/ResetPasswordForm";
import { toggleLogin, toggleRegister } from "../../actions/navbarActions";
import { clearUserError } from "../../actions/registrationActions";
import { linkToDashboard } from "../../utils/InternalLinks";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

export class RegistrationModal extends React.Component {
  constructor(props) {
    super(props);
    this.modalContentRef = React.createRef();
  }
  componentDidUpdate(prevProps) {
    if (this.props.type !== prevProps.type) {
      this.scrollToTop();
    }
  }

  scrollToTop = () => {
    const ref = this.modalContentRef;
    if (ref && ref.current) {
      const node = ReactDOM.findDOMNode(ref.current);
      node.scrollIntoView({ block: "start" });
    }
  };

  close = () => {
    this.props.dispatch(clearUserError());
    this.props.dispatch(toggleLogin(false));
    this.props.dispatch(toggleRegister(false));
  };

  closeButtonClick = () => {
    if (this.props.location.pathname === "/reset_password_form" || "/confirm") {
      this.setRedirectToHome();
    } else {
      this.close();
    }
  };

  render() {
    return (
      <Modal
        id="registration-modal"
        open={this.props.open}
        closeOnDimmerClick={false}
        aria-modal="true"
        tabIndex="-1"
        onClose={this.close}
        size={"tiny"}
      >
        <div
          className="content"
          id="registration-modal-content"
          ref={this.modalContentRef}
        >
          <div
              onMouseDown={ e => e.preventDefault() }
              onClick={e => e.target.focus()}
          >
            <FocusLock returnFocus={true} className="content">
              <Grid>
                <Grid.Row columns={16}>
                  <Grid.Column width={12}>
                    <h1>{this.getTitle(this.props.type)}</h1>
                  </Grid.Column>
                  <Grid.Column width={4} textAlign="right">
                    <Button
                      className="eli-modal-close-button"
                      aria-label="Close"
                      onClick={this.closeButtonClick}
                      icon
                    >
                      <Icon link name="close" size="large" />
                    </Button>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
              <hr />
              {this.getModalContent(this.props.type)}
            </FocusLock>
          </div>
        </div>
      </Modal>
    );
  }

  setRedirectToHome = () => {
    this.close();
    return this.props.history.push(linkToDashboard());
  };

  getModalContent(type) {
    switch (type) {
      case types.signup:
        return <SignUpForm scrollToTop={this.scrollToTop} />;
      case types.confirm:
        return <ConfirmationForm />;
      case types.success:
        return <RegistrationComplete />;
      case types.forgotpassword:
        return <ForgotPasswordForm />;
      case types.resetpassword:
        return <ResetPasswordForm scrollToTop={this.scrollToTop} />;
      default:
        break;
    }
  }

  getTitle(type) {
    const { t } = this.props;

    switch (type) {
      case types.signup:
        return t("registrationModal.titles.signUp");
      case types.confirm:
        return t("registrationModal.titles.confirmAccount");
      case types.success:
        return t("registrationModal.titles.success");
      case types.forgotpassword:
        return t("registrationModal.titles.forgotPassword");
      case types.resetpassword:
        return t("registrationModal.titles.changePassword");
      default:
        return "";
    }
  }
}

const types = {
  signup: "signup",
  confirm: "confirm",
  login: "login",
  success: "success",
  forgotpassword: "forgotpassword",
  resetpassword: "resetpassword",
  "enable2fa": "enable2fa",
  "mfaResponse": "mfaResponse"
};

const Extended = withTranslation()(connect()(withRouter(RegistrationModal)))

Extended.propTypes = { type: PropTypes.oneOf(Object.keys(types)) };
Extended.types = types;

export default Extended;